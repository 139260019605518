import { getAllProjects } from 'modules/app/selectors'
import { path, equals } from 'ramda'
import i18n from 'i18n'
import { DefaultStepState, PrettyRoverEventType, RoverEventType, StepState } from 'types/roverEvents'
import { isReconRover } from './rovers'
import config from 'config'
import { getUTCDate } from './dateTime'
import { getBackendTransformedValues } from './templates'

const eventBaseUrl = {
  [RoverEventType.RMA]: '/rmas',
  [RoverEventType.SALE]: '/sale_events',
  [RoverEventType.CUSTOMER_SUPPORT]: '/customer_support_events',
  [RoverEventType.SYSTEM_TYPE]: '/system_type_set_events',
  [RoverEventType.SHIPPING]: '/shipping_events',
  [RoverEventType.RENT]: '/rent_events',
  [RoverEventType.SERIAL]: '/serial_events',
  [RoverEventType.REGISTER]: '/register_events',
  [RoverEventType.RECALL_EVENT]: '/recall_events',
  [RoverEventType.RECALL]: '/recalls',
  [RoverEventType.DATA_ACQUISITION]: '/data_acquisition_events',
  [RoverEventType.DATA_ACQUISITION_REQUEST]: '/data_acquisition_request_events',
  [RoverEventType.DECOMMISSIONED]: '/decommission_events',
  [RoverEventType.REVERSE_DECOMMISSIONED]: '/reverse_decommission_events',
  [RoverEventType.SYSTEM_MAINTENANCE]: '/system_maintenance_events',
  [RoverEventType.MISSION]: '/lm_mission_events',
  [RoverEventType.CALIBRATION]: '/calibrations',
  [RoverEventType.ROVER_SYSTEM_TYPE]: '/rovers_system_types',
  [RoverEventType.ROVER_SYSTEM_TYPE_PROFILE]: '/profiles',
  [RoverEventType.ROVER_SYSTEM_TYPE_CONFIGURATION]: '/rovers_system_type_configurations',
  [RoverEventType.BUILD]: '/build_events',
  [RoverEventType.HISTORY_RESET]: '/history_reset_events',
  'rover': '/rovers',
}

const eventAttachmentsBaseUrl = {
  ...eventBaseUrl,
  [RoverEventType.CALIBRATION]: '/rover_calibrations',
  'licenseKey': '/license_keys',
}

const eventEditBaseUrl = {
  ...eventBaseUrl,
  [RoverEventType.CALIBRATION]: '/rover_calibrations',
}
/**
 * Rover events which can be selected to see additional information
 */
export const SelectableEvents = [
  RoverEventType.CUSTOM_SETTING,
  RoverEventType.COMPONENTINFO,
  RoverEventType.CALIBRATION,
  RoverEventType.RMA,
  RoverEventType.SALE,
  RoverEventType.RENT,
  // RoverEventType.MISSION,
  // RoverEventType.SHIPPING,
]
/**
 * Rover events which can be selected to see additional information (for non PLS users)
 */
export const UserSelectableEvents = [
  // RoverEventType.CUSTOM_SETTING,
  // RoverEventType.COMPONENTINFO,
  RoverEventType.CALIBRATION,
  // RoverEventType.RMA,
  // RoverEventType.MISSION,
  // RoverEventType.SHIPPING,
]

export const getSelectableEvents = (isUserAdmin, roverSerial) => {
  const events = isUserAdmin ? SelectableEvents : UserSelectableEvents
  if (isReconRover(roverSerial)) {
    return events.filter(event => event !== RoverEventType.CALIBRATION)
  }
  return events
}

const EventUrls = [RoverEventType.RENT]
export const getRoverEventUrl = (eventType, eventId) => {
  if (!EventUrls.includes(eventType)) {
    return null
  }
  return `${eventBaseUrl[eventType]}/${eventId}`
}
const ShowStatusesListEvents = [
  RoverEventType.RMA,
  RoverEventType.RENT,
]
/**
 * Checks if we should show a list of statuses in the event card
 */
export const isEventHasStatusesList = event => {
  if (!event) {
    return false
  }
  return ShowStatusesListEvents.includes(event.type)
}

export const AvailableEventTypesToAddAttachments = [
  RoverEventType.RMA,
  RoverEventType.SHIPPING,
  RoverEventType.CUSTOMER_SUPPORT,
  RoverEventType.SALE,
  // RoverEventType.RECALL_EVENT,
]

const EventsWithCheckList = [
  RoverEventType.RMA,
]
/**
 * Rover events which are rejectables
 */
const RejectableEvents = [
  // RoverEventType.DATA_ACQUISITION,
  // RoverEventType.DATA_ACQUISITION_REQUEST,
]
/**
 * Checks if event is rejectable
 */
export const isEventRejectable = event => {
  if (!event) {
    return false
  }
  return RejectableEvents.includes(event.type)
}

export const getEventDBLogsUrl = (eventId, eventType) => {
  return `${eventBaseUrl[eventType]}/${eventId}/db_logs`
}

const checkListBaseUrl = {
  [RoverEventType.RMA]: '/rma_checklist_steps',
}
export const getCheckListStepDBLogsUrl = (eventType, stepId) => {
  return `${checkListBaseUrl[eventType]}/${stepId}/db_logs`
}

/**
 * List of rover events which have db logs
 */
const ShowDBLogsEvents = [
  RoverEventType.RMA,
  RoverEventType.SALE,
  RoverEventType.CUSTOMER_SUPPORT,
  RoverEventType.SYSTEM_TYPE,
  RoverEventType.SHIPPING,
  RoverEventType.SERIAL,
  RoverEventType.DATA_ACQUISITION,
  RoverEventType.DATA_ACQUISITION_REQUEST,
  RoverEventType.RECALL_EVENT,
  RoverEventType.BUILD,
]
/**
 * Checks if event is available to use db logs
 */
export const isShowEventDBLogs = event => {
  if (!event) {
    return false
  }
  return ShowDBLogsEvents.includes(event.type)
}
/**
 * List of rover events to retrieve when user open a rover on the dashboard
 */
const RetrievableEvents = [
  RoverEventType.RMA,
  RoverEventType.SALE,
  RoverEventType.CUSTOMER_SUPPORT,
  RoverEventType.SYSTEM_TYPE,
  RoverEventType.SHIPPING,
  RoverEventType.REGISTER,
  RoverEventType.RENT,
  RoverEventType.SERIAL,
  RoverEventType.RECALL_EVENT,
  RoverEventType.DATA_ACQUISITION,
  RoverEventType.DECOMMISSIONED,
  RoverEventType.REVERSE_DECOMMISSIONED,
  RoverEventType.DATA_ACQUISITION_REQUEST,
  RoverEventType.SYSTEM_MAINTENANCE,
  RoverEventType.MISSION,
  RoverEventType.BUILD,
  RoverEventType.HISTORY_RESET,
]
export const getRoverEventsUrls = serial => {
  return RetrievableEvents.reduce((all, eventType) => ({
    ...all,
    [eventType]: `/rovers/${serial}${eventBaseUrl[eventType]}`,
  }), {})
}

export const getAddRoverEventURL = (eventType, serial) => {
  return `/rovers/${serial}${eventBaseUrl[eventType]}`
}

export const getRoverEventWorkListsURL = (eventType, eventId) => {
  return `${eventBaseUrl[eventType]}/${eventId}/work_list`
}

export const getRoverEventStepStatusesURL = (eventType, eventId) => {
  return `${eventBaseUrl[eventType]}/${eventId}/step_statuses`
}

/**
 * Generates the part of URL for fetching rover work lists based on the event type.
 *
 * @param {string} eventType - The type of the event to fetch the work lists for.
 * @returns {string} - The part of URL for the rover work lists.
 */
export const getRoverWorkListsURL = eventType => {
  return `${eventBaseUrl[eventType]}/work_lists`
}
/**
 * Checks whether work lists should be filtered by tag for a given event type.
 *
 * @param {string} eventType - The type of the event to check filtering for.
 */
export const shouldFilterWorkListsByTag = eventType => {
  return EventsFilteredByTag.includes(eventType)
}

/**
 * List of edtiable rover events
 */
const EditableEvents = [
  RoverEventType.RMA,
  RoverEventType.SALE,
  RoverEventType.CUSTOMER_SUPPORT,
  RoverEventType.SYSTEM_TYPE,
  RoverEventType.SHIPPING,
  RoverEventType.SERIAL,
  RoverEventType.DATA_ACQUISITION,
  RoverEventType.DECOMMISSIONED,
  RoverEventType.DATA_ACQUISITION_REQUEST,
  RoverEventType.SYSTEM_MAINTENANCE,
  RoverEventType.CALIBRATION,
  RoverEventType.RENT,
  RoverEventType.BUILD,
  RoverEventType.HISTORY_RESET,
  // RoverEventType.REVERSE_DECOMMISSIONED,
  // RoverEventType.RECALL_EVENT,
]
/**
 * Check if event is editable
 */
export const isEventEditable = (event, serial) => {
  if (!event) {
    return false
  }
  return EditableEvents.includes(event.type)
}
export const getEditRoverEventUrl = (eventId, eventType) => {
  return `${eventEditBaseUrl[eventType]}/${eventId}`
}
export const getStatusEventUrl = (eventId, eventType) => {
  return `${eventBaseUrl[eventType]}/${eventId}/statuses`
}
/**
 * List of rover events which require work list to be selected
 */
const EventsRequireWorkList = [
  RoverEventType.RENT,
]
/**
 * Checks if event requires a work list
 */
export const isEventRequireWorkList = event => {
  if (!event) {
    return false
  }
  return EventsRequireWorkList.includes(event.type)
}
/**
 * List of rover events which can select work lists
 */
export const EventsWithWorkList = [
  RoverEventType.BUILD,
  RoverEventType.RENT,
  RoverEventType.DATA_ACQUISITION_REQUEST,
]
/**
 * List of rover events should be filtered by tag
 */
export const EventsFilteredByTag = [
  RoverEventType.BUILD,
]
/**
 * List of DAR types which do not require work lists (issue #349)
 */
const DarTypesWithoutWorkList = [
  'rental_validation',
]
/**
 * Checks if event has a work lists and able to select them
 */
export const isEventHasWorkList = event => {
  if (!event) {
    return false
  }
  return EventsWithWorkList.includes(event.type) && !DarTypesWithoutWorkList.includes(event.darEventType)
}
/**
 * Returns a list of work lists available to the `eventType` rover event
 * Filtered by `target` value
 * @param {String} eventType
 * @param {Array} workLists
 * @returns
 */
export const getWorkListsByEventType = (eventType, workLists) => {
  if (eventType === RoverEventType.DATA_ACQUISITION_REQUEST) {
    return workLists.filter(workList => workList.target === 'data_acquisition_request_event')
  }
  if (eventType === RoverEventType.RENT) {
    return workLists.filter(workList => workList.target === 'rover_rent_event')
  }
  return workLists.filter(workList => workList.target !== 'rover_rent_event' && workList.target !== 'data_acquisition_request_event')
}

export const isEventHasCheckList = (event, serial) => {
  if (!event) {
    return false
  }
  const show = EventsWithCheckList.includes(event.type)
  if (event.type === RoverEventType.RMA) {
    if (event.rma_type === 'rover') {
      return true
    }
  } else {
    return show
  }
}
/**
 * List of deletable rover events
 */
const DeletableEvents = [
  RoverEventType.REGISTER,
]
/**
 * Checks if event is deletable
 */
export const isEventDeletable = event => {
  if (!event) {
    return false
  }
  return DeletableEvents.includes(event.type)
}
export const getDeleteRoverEventUrl = (eventId, eventType) => {
  return `${eventBaseUrl[eventType]}/${eventId}`
}
export const isChildEvent = event => {
  if (!event) {
    return false
  }
  const isChildRMAEvent = event.parent && event.type === RoverEventType.RMA
  const isChildShippingEvent = (event.rma || event.rent_event) && event.type === RoverEventType.SHIPPING
  return isChildRMAEvent || isChildShippingEvent
}

export const getParentEvent = (event, events) => {
  if (!isChildEvent(event)) {
    return null
  }
  const parentEventId = (
    path(['parent', 'id'], event) ||
    path(['rma', 'id'], event) ||
    path(['rent_event', 'id'], event)
  )
  if (!parentEventId) {
    return null
  }
  return events.find(re => re.id === parentEventId)
}
const ChildEventURL = {
  [RoverEventType.RMA]: 'children',
  [RoverEventType.SHIPPING]: 'shipping_events',
}
export const getChildEventsUrls = (parentEventType, parentEventId, eventType) => {
  return `${eventBaseUrl[parentEventType]}/${parentEventId}/${ChildEventURL[eventType]}`
}
export const getRoverEventAttachmentsUrl = (roverEventId, eventType) => {
  return `${eventAttachmentsBaseUrl[eventType]}/${roverEventId}/attachments`
}
export const getSoftwareVersion = json => {
  if (!json) return 'Unknown version'
  if (typeof json === 'string') {
    try {
      const newJson = JSON.parse(json)
      return path(['version', 'major'], newJson) + '.' +
      path(['version', 'minor'], newJson) + '.' +
      path(['version', 'patch'], newJson)
    } catch (e) {
      return 'Unknown version'
    }
  }
  return path(['version', 'major'], json) + '.' +
    path(['version', 'minor'], json) + '.' +
    path(['version', 'patch'], json)
}

export const getEventProject = (link, state, providedProjects) => {
  const projects = providedProjects || getAllProjects(state)
  const projectId = link.split('/projects/')[1]
  return projects.find(project => project.id === projectId)
}

export const DARequestStatus = {
  OPEN: 'open',
  FAILED: 'failed',
  SUCCESS: 'success',
}

export const DAStatus = {
  NONE: 'none',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  PROCESSING: 'processing',
}

export const RMAStatus = {
  AWAITING_SYSTEM_ARRIVAL: 'awaiting_system_arrival',
  AWAITING_VENDOR: 'awaiting_vendor',
  WAITING_ON_CUSTOMER: 'waiting_on_customer',
  IN_PRODUCTION: 'in_production',
  BORESIGHTING: 'boresighting',
  FINAL_QC: 'final_qc',
  READY_TO_SHIP: 'ready_to_ship',
  DISPATCHED: 'dispatched',
  REPAIR_CANCELLED: 'repair_cancelled',
}

const StatusColor = {
  [DARequestStatus.SUCCESS]: 'green',
  [DARequestStatus.FAILED]: 'red',
  [DAStatus.REJECTED]: 'red',
  [DAStatus.ACCEPTED]: 'green',
  [RMAStatus.DISPATCHED]: 'green',
}

export const getStatusColor = status => {
  return StatusColor[status] || 'orange'
}

export const getStatusLabel = (type, status) => {
  if (type === RoverEventType.DATA_ACQUISITION && !status) {
    return i18n.t(`event.status.${type}.none`)
  }
  return i18n.t(`event.status.${type}.${status}`)
}

export const DARequestStatuses = [
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION_REQUEST, DARequestStatus.OPEN), value: DARequestStatus.OPEN, id: DARequestStatus.OPEN },
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION_REQUEST, DARequestStatus.FAILED), value: DARequestStatus.FAILED, id: DARequestStatus.FAILED },
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION_REQUEST, DARequestStatus.SUCCESS), value: DARequestStatus.SUCCESS, id: DARequestStatus.SUCCESS },
]

export const DAStatuses = [
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION, DAStatus.NONE), value: DAStatus.NONE, id: DAStatus.NONE },
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION, DAStatus.ACCEPTED), value: DAStatus.ACCEPTED, id: DAStatus.ACCEPTED },
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION, DAStatus.REJECTED), value: DAStatus.REJECTED, id: DAStatus.REJECTED },
  { label: getStatusLabel(RoverEventType.DATA_ACQUISITION, DAStatus.PROCESSING), value: DAStatus.PROCESSING, id: DAStatus.PROCESSING },
]

const ShowStatusesEvents = [
  RoverEventType.RMA,
  // RoverEventType.DATA_ACQUISITION,
  RoverEventType.DATA_ACQUISITION_REQUEST,
  RoverEventType.RENT,
]

/*
const StatusesByEventType = {
  [RoverEventType.RMA]: RMAStatus,
  [RoverEventType.DATA_ACQUISITION_REQUEST]: DARequestStatus,
}
*/

export const isShowEventStatuses = event => {
  if (!event) {
    return false
  }
  const show = ShowStatusesEvents.includes(event.type)
  if (event.type === RoverEventType.RMA) {
    if (event.rma_type === 'rover') {
      return true
    }
  } else {
    return show
  }
}

export const isDAEventHasApprovedCalibration = (event, roverEvents) => {
  return roverEvents.find(re => (
    re.type === RoverEventType.CALIBRATION &&
    re.data_acquisition_events.includes(event.id)
  ))
}

export const getDefaultCheckListSteps = steps => {
  return steps.map(step => ({
    work_state: DefaultStepState,
    prev_work_state: DefaultStepState,
    comment: '',
    prev_comment: '',
    name: step.name,
    id: step.id,
    stepId: step.id,
    isAdded: false,
  }))
}

export const isCheckListStepValid = (workState, comment) => {
  if (workState === 'did_not_exist_at_time') {
    return true
  }
  if (isStepCommentRequired(workState)) {
    return Boolean(comment)
  }
  return workState !== StepState.NOT_DONE
}

export const isCheckListStepRequired = (step, previousStatus, currentStatus) => {
  if (step.work_state === 'did_not_exist_at_time') {
    return false
  }
  return (step.required_from || '').includes(previousStatus) || (step.required_to || '').includes(currentStatus)
}

export const getStepStateCommentLabel = workState => {
  if (workState === StepState.DOES_NOT_APPLY) {
    return 'Why does this not apply?'
  }
  if (workState === StepState.DONE) {
    return 'Enter any details'
  }
  return 'Comment'
}

export const isStepCommentRequired = workState => {
  if (workState === StepState.DOES_NOT_APPLY) {
    return true
  }
  return false
}

export const isCheckListValid = (prevStatus, currentStatus, shouldShowCheckList, allChecklistSteps, steps) => {
  const checkListStepsForCurrentStatus = !currentStatus
    ? []
    : allChecklistSteps.filter(step => isCheckListStepRequired(step, prevStatus, currentStatus))
  const checkListStepsForNotCurrentStatus = !currentStatus
    ? []
    : allChecklistSteps.filter(step => !isCheckListStepRequired(step, prevStatus, currentStatus))
  return shouldShowCheckList
    ? checkListStepsForCurrentStatus.every(step => {
      const filledStep = steps.find(s => s.stepId === step.id)
      return filledStep && isCheckListStepValid(filledStep.work_state, filledStep.comment)
    }) && checkListStepsForNotCurrentStatus.every(step => {
      const filledStep = steps.find(s => s.stepId === step.id)
      return filledStep ? isStepCommentRequired(filledStep.work_state) ? filledStep.comment : true : false
    })
    : true
}

export function getRoverEventSerial (roverEvent) {
  return roverEvent && roverEvent.rover && roverEvent.rover.serial
}

export function getEventsForEventSalesOrder (
  event,
  roverEvents,
  secondaryType,
) {
  const roverSerial = event.rover && event.rover.serial
  const eventSalesOrder = event.sale_order
  return roverEvents
    .filter(re => re.type === secondaryType && getRoverEventSerial(re) === roverSerial)
    .filter(re => re.sale_order === eventSalesOrder && eventSalesOrder && eventSalesOrder !== 'N/A')
}

export function getAssociatedEventTemplate (event, daEventTypes, darEvents, daEvents) {
  if (event.type === RoverEventType.DATA_ACQUISITION) {
    return getDAEventTemplate(event, daEventTypes, darEvents, daEvents)
  }
  if (event.type === RoverEventType.DATA_ACQUISITION_REQUEST) {
    return getDARequestEventTemplate(event, daEventTypes, darEvents, daEvents)
  }
  return ''
}

export const getDARequestEventTemplate = (entry, daEventTypes) => {
  if (!entry) {
    return ''
  }
  const eventType = (daEventTypes.find(p => p.id === entry.event_type) || {}).label || ''
  const eventDescription = entry.description ? entry.description : ''
  const status = (DARequestStatuses.find(s => s.id === path(['status', 'status'], entry)) || {}).label || ''
  const date = getUTCDate(entry.created).format(config.DATE_FORMAT)
  // const priority = (PriorityOptions.find(p => p.id === entry.priority) || {}).label || ''
  return [entry.rover.serial, date, PrettyRoverEventType[entry.type], eventType, eventDescription, status].filter(Boolean).join(' | ')
}

export const getDAEventTemplate = (entry, daEventTypes, darEvents) => {
  if (!entry) {
    return ''
  }
  const darEvent = darEvents.find(re => re.id === path(['data_acquisition_request_event', 'id'], entry))
  const eventType = darEvent ? (daEventTypes.find(p => p.id === darEvent.event_type) || {}).label || '' : ''
  // const eventDescription = entry.sale_order ? entry.sale_order : ''
  const status = DAStatuses.find(s => s.id === path(['status'], entry))
  const statusLabel = (status || {}).label || 'Open'
  const date = getUTCDate(entry.dateSort).format(config.DATE_FORMAT)
  // const priority = (PriorityOptions.find(p => p.id === entry.priority) || {}).label || ''
  const statusSubText = status
    ? status.id === DAStatus.REJECTED
      ? entry.rejection_reason
      : status.id === DAStatus.ACCEPTED
        ? entry.accepted_reason
        : ''
    : ''
  return [
    entry.rover.serial,
    date,
    PrettyRoverEventType[entry.type],
    eventType,
    statusLabel + (statusSubText ? ` (${statusSubText})` : ''),
  ].filter(Boolean).join(' | ')
}

export function getDefaultFilterString (filters) {
  return filters.map(({ defaultFilter, id }) => {
    if (defaultFilter.length <= 0) {
      return ''
    }
    return id + '=' + defaultFilter.map(filter => filter.value).join(',')
  }).join('&')
}

export const isFormFieldChanged = (templateField, newValue, oldValue, row, state, extra) => {
  if (!templateField) {
    return false
  }
  const checkIsChanged = templateField.isChanged
  if (typeof checkIsChanged === 'function') {
    return checkIsChanged(newValue, row, state, extra, templateField)
  } else if (!equals(newValue, oldValue)) {
    return true
  }
  return false
}

const getChangedValue = (templateField, newValue, originalValue, row, state, extra) => {
  if (!templateField) {
    return newValue
  }
  const getChangedValue = templateField.getChangedValue
  if (typeof getChangedValue === 'function') {
    return getChangedValue(templateField, newValue, originalValue, row, state, extra)
  }
  return newValue
}

export const isSomeFieldChanged = (eventValues, template, state, row, extra) => {
  if (!eventValues) {
    return false
  }
  const transformedValues = getBackendTransformedValues(extra, state, eventValues, eventValues, template)
  return Object.keys(transformedValues).some(key => {
    return isFormFieldChanged(
      template[key],
      transformedValues[key],
      row[key],
      row,
      state,
      extra,
    )
  }, {})
}

export const getChangedValues = (eventValues, template, state, row, extra) => {
  const transformedValues = getBackendTransformedValues(extra, state, eventValues, eventValues, template)
  const onlyChangedValues = Object.keys(transformedValues).reduce((all, key) => {
    const newValue = transformedValues[key]
    const isChanged = isFormFieldChanged(
      template[key],
      newValue,
      row[key],
      row,
      state,
      extra,
    )
    if (isChanged) {
      const changedValue = getChangedValue(
        template[key],
        newValue,
        row[key],
        row,
        state,
        extra,
      )
      return {
        ...all,
        [key]: changedValue,
      }
    }
    return all
  }, {})
  return { transformed: transformedValues, changed: onlyChangedValues }
}

const HasCalibrationOptions = [
  { label: 'Yes', value: 'yes', id: 'yes' },
  { label: 'No', value: 'no', id: 'no' },
  { label: 'All', value: 'all', id: 'all' },
]
export const getDefaultDAEventsFilters = daEventTypes => {
  const filters = [
    {
      id: 'status',
      label: 'Status',
      defaultFilter: DAStatuses,
      filterOptions: DAStatuses,
    },
    {
      id: 'request_types',
      label: 'Data acquisition type',
      defaultFilter: [],
      filterOptions: daEventTypes,
    },
    {
      id: 'has_calibration',
      label: 'Has calibration?',
      multiple: false,
      defaultFilter: [HasCalibrationOptions[2]],
      filterOptions: HasCalibrationOptions,
    },
  ]
  return filters
}
